import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import StyledSectionWrapper from "../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../shared/StyledContainerWrapper";
import StyledButton from "../shared/StyledButton";

import { scrollToTop } from "../../utils/helpers";
import { Icon } from "../../globals/icons";
import { StyledSubTitle2 } from "../shared/StyledTypography";
import { mq } from "../../globals/utils";

const StyledSectionWrapperMar = styled(StyledSectionWrapper)`
  padding-top: 380px;
  padding-bottom: 250px;
  background-color: #f0f4ff;
  margin-top: -330px;
  ${mq.between("md", "lg")} {
    margin-top: -360px;
  }
  ${mq.below.md} {
    margin-top: -375px;
    padding-top: 430px;
  }
`;

const StyledEventsListWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 120px;
  padding-top: 70px;
  .eventImg {
    padding-bottom: 32px;
    text-align: center;
    &.filterShadow {
      img {
        filter: drop-shadow(-15.2174px 7.60868px 43.1158px rgba(0, 0, 0, 0.45));
        -webkit-filter: drop-shadow(-15.2174px 7.60868px 43.1158px rgba(0, 0, 0, 0.45));
        z-index: 1;
        position: relative;
        padding: 60px 0;
        margin: -60px 0;
      }
    }
    img {
      border-radius: 8px;
      display: inline-block;
    }
  }
  .grid {
    display: grid;
    .alignSelfStart {
      align-self: start;
    }
    .alignSelfend {
      align-self: end;
    }
  }
  .textCenter {
    text-align: center;
    max-width: 336px;
    margin: 0 auto;
  }
  .borderRight {
    border-right: 1px solid #c6d6ff;
  }
  .buttonWrapper {
    padding-top: 26px;
    &.flexCenter {
      display: flex;
      justify-content: center;
    }
    button {
      &:hover {
        .right-arrow {
          transform: translateX(4px);
        }
      }
      .right-arrow {
        margin-left: 8px;
        transition: all 0.3s ease-in-out;
      }
    }
  }
  ${mq.between("md", "lg")} {
    grid-gap: 90px;
  }
  ${mq.below.md} {
    grid-template-columns: 1fr;
    grid-gap: 50px;
    .borderRight {
      border-right: 0;
      border-bottom: 1px solid #c6d6ff;
    }
  }
`;

const AwsEventCaseStudy = () => {
  return (
    <StyledSectionWrapperMar>
      <StyledContainerWrapper>
        <StyledEventsListWrapper>
          <div className="grid">
            <div className="alignSelfStart">
              <div className="eventImg filterShadow">
                <img
                  width="197"
                  src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1678797490/main-web/aws-microsite/philips-healthcare_ja45v5.png"
                  alt="Philips Healthcare"
                />
              </div>
              <StyledSubTitle2 className="textCenter" variant="neutral_800" fontWeight="font_400">
                How Philips Healthcare accelerated development by up to 4X with Hasura
              </StyledSubTitle2>
            </div>
            <div className="buttonWrapper flexCenter alignSelfend">
              <Link to="/case-studies/philips/" onClick={scrollToTop}>
                <StyledButton variant="neutral_400_transparent_bg" size="download" fontWeight="500">
                  Read the Case Study
                  <Icon
                    className="right-arrow"
                    variant="arrow_right"
                    size="sm"
                    color="neutral_1000"
                  />
                </StyledButton>
              </Link>
            </div>
          </div>
          {/* <div className="borderRight" />
          <div className="grid">
            <div className="alignSelfStart">
              <div className="eventImg">
                <img src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1678100941/main-web/event-listing-image_d1nul0.png" alt="Hasura AWS" />
              </div>
              <StyledSubTitle1 variant="neutral_800" fontWeight="font_bold" paddingBottom="pb4">#Shipfast on AWS with the Hasura API Platform</StyledSubTitle1>
              <StyledDesc2 variant="neutral_800">Quickly connect API consumers to data hosted in a variety of services in AWS.</StyledDesc2>
            </div>
            <div className="buttonWrapper alignSelfend">
              <Link to="/events/workshop/ship-fast-on-aws-with-the-hasura-api-platform/" onClick={scrollToTop}>
                <StyledButton variant="neutral_400_transparent_bg" size="download" fontWeight="500">
                  Register for the Event
                  <Icon className="right-arrow" variant="arrow_right" size="sm" color="neutral_1000" />
                </StyledButton>
              </Link>
            </div>
          </div> */}
        </StyledEventsListWrapper>
      </StyledContainerWrapper>
    </StyledSectionWrapperMar>
  );
};

export default AwsEventCaseStudy;
