import React from "react";
import styled from "styled-components";

import StyledSectionWrapper from "../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../shared/StyledContainerWrapper";
import StyledButton from "../shared/StyledButton";

import { Icon } from "../../globals/icons";
import { Image } from "../../globals/UIKit";
import { StyledDisplay2Xs } from "../shared/StyledTypography";
import { removePaddBottom } from "../shared/CommonStyled";
import { mq } from "../../globals/utils";

const StyledGetStartedAws = styled.div`
  padding: 88px 130px;
  background: #FFFFFF;
  box-shadow: 0px 1px 3px rgba(28, 38, 63, 0.1), 0px 1px 2px rgba(28, 38, 63, 0.06);
  border-radius: 16px;
  display: grid;
  grid-template-columns: 215px 1fr;
  grid-gap: 60px;
  margin-top: -250px;
  .brandAws {
    border: 1.5px solid #ECEDF0;
    border-radius: 25px;
    padding: 42px 44px;
    align-self: start;
  }
  .buttonWrapper {
    padding-top: 20px;
    button {
      &:hover {
        .right-arrow {
          transform: translateX(4px);
        }
      }
      .right-arrow {
        margin-left: 8px;
        transition: all .3s ease-in-out;
      }
    }
  }
  ${mq.between("md", "lg")} {
    padding: 48px;
  }
  ${mq.below.md} {
    padding: 32px;
    grid-template-columns: 1fr;
    grid-gap: 32px;
    .brandAws {
      padding: 24px;
      width: fit-content;
      img {
        min-width: 100px;
        width: 100px;
      }
    }
  }
`;


const GetStartedWithAws = () => {
  return (
    <StyledSectionWrapper css={removePaddBottom}>
      <StyledContainerWrapper>
        <StyledGetStartedAws>
          <div className="brandAws">
            <Image
              src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1678776920/main-web/aws-microsite/aws_ylxk9h.png"
              alt="aws"
              minWidth="124px"
              width="124px"
            />
          </div>
          <div>
            <StyledDisplay2Xs variant="neutral_800" fontWeight="font_bold">Get started now with Hasura Cloud on AWS</StyledDisplay2Xs>
            <div className="buttonWrapper">
              <a href="https://aws.amazon.com/marketplace/pp/prodview-h7qmhw3draxh4?sr=0-1&ref_=beagle&applicationId=AWSMPContessa" target="_blank" rel="noopener noreferrer">
                <StyledButton variant="neutral_400_transparent_bg" size="download" fontWeight="500">
                  Get Hasura Cloud on AWS
                  <Icon className="right-arrow" variant="arrow_right" size="sm" color="neutral_1000" />
                </StyledButton>
              </a>
            </div>
          </div>
        </StyledGetStartedAws>
      </StyledContainerWrapper>
    </StyledSectionWrapper>
  );
};

export default GetStartedWithAws;
