import React from "react";
import styled from "styled-components";

import StyledSectionWrapper from "../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../shared/StyledContainerWrapper";
import IndivFeatureCard from "./IndivFeatureCard";

import { StyledSubTitle1, StyledBody1, StyledDesc2 } from "../shared/StyledTypography";
import { removePaddBottom } from "../shared/CommonStyled";
import { mq } from "../../globals/utils";

const StyledPlatformEnterpriseWrapper = styled.div`
  padding-bottom: 120px;
  .pr32 {
    padding-right: 32px;
  }
  .featureListWrapper {
    padding-top: 78px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 90px;
  }
  ${mq.between("md", "lg")} {
    padding-bottom: 80px;
    .featureListWrapper {
      grid-gap: 50px;
    }
  }
  ${mq.below.md} {
    padding-bottom: 60px;
    .featureListWrapper {
      padding-top: 60px;
      grid-template-columns: 1fr;
      grid-gap: 50px;
    }
  }
`;

const StyledCoreDataWrapper = styled.div`
  .mW540 {
    max-width: 540px;
    margin: 0 auto;
    padding-bottom: 44px;
    text-align: center;
  }
  .erdDaigram {
    background: #DFE8FF;
    border-radius: 16px;
    padding: 80px 200px;
    position: relative;
    img {
      display: inline-block;
    }
  }
  .featurePointsWrapper {
    padding-top: 46px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 120px;
    .flexStart {
      display: flex;
      align-items: flex-start;
      svg {
        margin-top: 4px;
        margin-right: 16px;
        min-width: 24px;
      }
    }
  }
  ${mq.between("md", "lg")} {
    .erdDaigram {
      padding: 60px 80px;
    }
    .featurePointsWrapper {
      grid-gap: 48px;
    }
  }
  ${mq.below.md} {
    .mW540 {
      padding-bottom: 24px;
    }
    .erdDaigram {
      padding: 24px;
    }
    .featurePointsWrapper {
      grid-template-columns: 1fr;
      grid-gap: 24px;
    }
  }
`;

const CodeBrowser = () => (
  <svg width="70" height="72" viewBox="0 0 70 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse cx="35" cy="36" rx="35" ry="36" fill="#C2F4FF"/>
    <path d="M48.3333 32H21.6667M37.6667 43.3333L41 40L37.6667 36.6667M32.3333 36.6667L29 40L32.3333 43.3333M21.6667 30.4L21.6667 41.6C21.6667 43.8402 21.6667 44.9603 22.1026 45.816C22.4861 46.5686 23.098 47.1805 23.8507 47.564C24.7063 48 25.8264 48 28.0667 48H41.9333C44.1735 48 45.2936 48 46.1493 47.564C46.9019 47.1805 47.5139 46.5686 47.8974 45.816C48.3333 44.9603 48.3333 43.8402 48.3333 41.6V30.4C48.3333 28.1598 48.3333 27.0397 47.8974 26.184C47.5139 25.4314 46.9019 24.8195 46.1493 24.436C45.2936 24 44.1735 24 41.9333 24L28.0667 24C25.8264 24 24.7063 24 23.8507 24.436C23.098 24.8195 22.4861 25.4314 22.1026 26.184C21.6667 27.0397 21.6667 28.1598 21.6667 30.4Z" stroke="#1F2A37" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
)

const ZapFast = () => (
  <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="35" cy="35" r="35" fill="#FFD9E7"/>
  <path d="M31 42.3333H23.6667M27.6667 35H21.6667M31 27.6667H24.3333M41.6667 23L32.8714 35.3133C32.4821 35.8583 32.2875 36.1308 32.2959 36.358C32.3033 36.5558 32.3981 36.7402 32.5548 36.8611C32.7348 37 33.0697 37 33.7394 37H40.3333L39 47L47.7952 34.6867C48.1845 34.1417 48.3792 33.8692 48.3708 33.642C48.3634 33.4442 48.2686 33.2598 48.1119 33.1389C47.9319 33 47.597 33 46.9273 33H40.3333L41.6667 23Z" stroke="#1F2A37" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
)

const ShieldTick = () => (
  <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="35" cy="35" r="35" fill="#DFE8FF"/>
    <path d="M31 35.3333L33.6667 38L39.6667 32M45.6667 36C45.6667 42.5446 38.5281 47.3045 35.9307 48.8198C35.6355 48.992 35.4879 49.0781 35.2796 49.1228C35.1179 49.1575 34.8821 49.1575 34.7204 49.1228C34.5121 49.0781 34.3645 48.992 34.0694 48.8198C31.472 47.3045 24.3333 42.5446 24.3333 36V29.6235C24.3333 28.5574 24.3333 28.0244 24.5077 27.5663C24.6617 27.1615 24.912 26.8004 25.2369 26.514C25.6047 26.1899 26.1037 26.0028 27.1019 25.6285L34.2509 22.9476C34.5281 22.8436 34.6667 22.7916 34.8093 22.771C34.9358 22.7528 35.0642 22.7528 35.1907 22.771C35.3333 22.7916 35.4719 22.8436 35.7491 22.9476L42.8981 25.6285C43.8963 26.0028 44.3954 26.1899 44.7631 26.514C45.088 26.8004 45.3383 27.1615 45.4923 27.5663C45.6667 28.0244 45.6667 28.5574 45.6667 29.6235V36Z" stroke="#1F2A37" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
)

const Database3 = () => (
  <svg width="70" height="72" viewBox="0 0 70 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse cx="35" cy="36" rx="35" ry="36" fill="#EDE1FF"/>
    <path d="M37.6667 46.6667C37.6667 48.1394 36.4728 49.3333 35 49.3333C33.5272 49.3333 32.3333 48.1394 32.3333 46.6667M37.6667 46.6667C37.6667 45.1939 36.4728 44 35 44M37.6667 46.6667H47M32.3333 46.6667C32.3333 45.1939 33.5272 44 35 44M32.3333 46.6667H23M35 44V38.6667M47 26.6667C47 28.8758 41.6274 30.6667 35 30.6667C28.3726 30.6667 23 28.8758 23 26.6667M47 26.6667C47 24.4575 41.6274 22.6667 35 22.6667C28.3726 22.6667 23 24.4575 23 26.6667M47 26.6667V34.6667C47 36.88 41.6667 38.6667 35 38.6667M23 26.6667V34.6667C23 36.88 28.3333 38.6667 35 38.6667" stroke="#1F2A37" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
)

export const CheckCircle = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="12" fill="#F0F4FF"/>
    <path d="M17.3334 8L10 15.3333L6.66669 12" stroke="#1E56E3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
)

const featureCardState = [
  {
    imgSrc: <CodeBrowser />,
    title: "Instantly provide a GraphQL or REST API on top of your database",
    desc: "Easily connect Hasura to your new or existing databases and start engaging with your users with a secure and scalable API – no more language limitations or complex SQL queries.",
  },
  {
    imgSrc: <ZapFast />,
    title: "Blazing-fast and highly optimized API performance",
    desc: "No more wasted development cycles optimizing queries for bespoke data sources. Maintain the flexibility to optimize and customize only your most high-value queries. Cache common queries to avoid database performance and response latency penalties.",
  },
  {
    imgSrc: <ShieldTick />,
    title: "Granular RLS-style authorization ensures secure data access",
    desc: "Eliminate the need for a secondary permissions layer entirely with Hasura’s built-in authorization engine. Expose GraphQL APIs to internal and external developers with authorization rules enforceable to the model level.",
  },
  {
    imgSrc: <Database3 />,
    title: "Dynamic data federation – join data from anything to anything",
    desc: "Combine multiple data sources into a single GraphQL API endpoint. Reduce client transactions and increase performance by sending multiple queries across any number of data sources to return a single, optimized response to data consumers.",
  },
]

const PlatformEnterprise = () => {
  return (
    <StyledSectionWrapper css={removePaddBottom}>
      <StyledContainerWrapper>
        <StyledPlatformEnterpriseWrapper>
          <StyledSubTitle1 variant="neutral_800" paddingBottom="pb12">The API Platform for enterprise data access</StyledSubTitle1>
          <StyledBody1 className="pr32" variant="neutral_800">Developers love Hasura because it takes little time to go from data to an API. Architects rely on Hasura because it enables high-performance and secure GraphQL and REST APIs from a single platform. And engineering leaders appreciate Hasura as it empowers developers to deliver rich apps and user experiences in a fraction of the time of traditional approaches.</StyledBody1>
          <div className="featureListWrapper">
            {
              featureCardState.map((list, index) => (
                <IndivFeatureCard key={index} list = { list } />
              ))
            }
          </div>
        </StyledPlatformEnterpriseWrapper>
        <StyledCoreDataWrapper>
          <StyledSubTitle1 className="mW540" variant="neutral_800" paddingBottom="pb12">Integrate with core data, security, and application services on AWS</StyledSubTitle1>
          <div className="erdDaigram">
            <img src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1678790874/main-web/aws-microsite/integrate-core-data_xqajda.png" alt="integrate-core-data" />
          </div>
          <div className="featurePointsWrapper">
            <div className="flexStart">
              <CheckCircle />
              <StyledDesc2 variant="neutral_800">Build new applications or add additional features to existing applications fast.</StyledDesc2>
            </div>
            <div className="flexStart">
              <CheckCircle />
              <StyledDesc2 variant="neutral_800">Migrate and modernize existing applications within a short timeframe.</StyledDesc2>
            </div>
            <div className="flexStart">
              <CheckCircle />
              <StyledDesc2 variant="neutral_800">Replace direct database access with a unified, federated core data service.</StyledDesc2>
            </div>
          </div>
        </StyledCoreDataWrapper>
      </StyledContainerWrapper>
    </StyledSectionWrapper>
  );
};

export default PlatformEnterprise;
