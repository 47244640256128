export const PROD_ROOT_DOMAIN = `https://hasura.io/`;
// export const PROD_ROOT_DOMAIN = `http://localhost:8000/`;

export const HASURA_HOMEPAGE_URL = `/`;

export const isBrowser = typeof window !== "undefined";

export const FRESHDESK_JOBS_SECTION_LINK = `#hasura-open-roles`;

export const HASURACON_22_URL = `/events/hasura-con-2022/`;

export const BRAND_PAGE_URL = `/brand/`;

export const STORE_PAGE_URL = `/store/`;

export const STORE_CART_PAGE_URL = `${STORE_PAGE_URL}cart/`;

export const STORE_CHECKOUT_PAGE_URL = `${STORE_PAGE_URL}checkout/`;

export const STORE_ORDER_SUCCESS_PAGE_URL = `${STORE_PAGE_URL}success/`;

export const HASURA_TEE_URL = `/store/hasura_tee/`;

export const HASURA_HUB_URL = `/hub/`;

export const HASURA_DOCS_LINK = `https://hasura.io/docs/latest/index/`;
