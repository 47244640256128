import React from "react";
import styled from "styled-components";

import { StyledDesc1, StyledDesc2 } from "../shared/StyledTypography";

const StyledIndivFeatureCardWrapper = styled.div`
  .mxW300 {
    max-width: 300px;
  }
  svg {
    margin-bottom: 20px;
  }
`;


const IndivFeatureCard = ({list}) => {
  return (
    <StyledIndivFeatureCardWrapper>
      {list.imgSrc}
      <StyledDesc1 className="mxW300" variant="neutral_800" fontWeight="font_600" paddingBottom="pb16">{list.title}</StyledDesc1>
      <StyledDesc2 variant="neutral_800">{list.desc}</StyledDesc2>
    </StyledIndivFeatureCardWrapper>
  );
};

export default IndivFeatureCard;
