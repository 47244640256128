import React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";
import HeroBanner from "../components/awsmicrosite/HeroBanner";
import PlatformEnterprise from "../components/awsmicrosite/PlatformEnterprise";
import AwsEventCaseStudy from "../components/awsmicrosite/AwsEventCaseStudy";
import GetStartedWithAws from "../components/awsmicrosite/GetStartedWithAws";

const ogImage = {
  ogImage: "https://res.cloudinary.com/dh8fp23nd/image/upload/v1678805261/main-web/aws-microsite/hasura-cloud-aws_dmw3jk.png",
};

const canonicalUrl = "https://hasura.io/aws/";

const IndexPage = props => {
  return (
    <Layout location={props.location}>
      <Seo
        title="Get Start Now with Hasura Cloud on AWS"
        description="Accelerate your journey to building modern, cloud-native applications with Hasura Cloud on AWS, the API platform for enterprise data access."
        meta={ogImage}
        canonicalLink={canonicalUrl}
      />
      <HeroBanner />
      <PlatformEnterprise />
      <AwsEventCaseStudy />
      <GetStartedWithAws />
    </Layout>
  );
};

export default IndexPage;
