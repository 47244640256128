import { isBrowser } from "./constants";

export const scrollToTop = (topPositon = 0) => {
  if (isBrowser) {
    window.scrollTo({
      top: topPositon,
      left: 0,
      behavior: "smooth",
    });
  }
};

export const scrollToViewPosition = elementId => {
  if (isBrowser) {
    const elementRef = document.getElementById(elementId);

    if (elementRef) {
      return elementRef.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
    }
  }
};
