import React from "react";
import styled, { css } from "styled-components";
import { Link } from "gatsby";

import StyledSectionWrapper from "../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../shared/StyledContainerWrapper";
import StyledButton from "../shared/StyledButton";

import { scrollToTop } from "../../utils/helpers";
import { Icon } from "../../globals/icons";
import { Flex, Image, Heading, Text } from "../../globals/UIKit";
import { removePaddBottom } from "../shared/CommonStyled";
import { mq } from "../../globals/utils";

const StyledSectionWrapperGradient = styled(StyledSectionWrapper)`
  background: linear-gradient(0deg, #FFFFFF 7.09%, #CFE8FF 100%);
`;

const StyledTopBannerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  .buttonWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px 0;
    button {
      margin: 0 8px;
      &:hover {
        .right-arrow {
          transform: translateX(5px);
        }
      }
      .right-arrow {
        margin-left: 8px;
        transition: all .3s ease-in-out;
      }
    }
  }
  ${mq.below.md} {
    .buttonWrapper {
      flex-direction: column;
      padding-bottom: 0;
      button {
        margin: 8px 0;
      }
    }
  }
`;


const HeroBanner = () => {
  return (
    <StyledSectionWrapperGradient css={removePaddBottom}>
      <StyledContainerWrapper>
        <StyledTopBannerWrapper>
          <Flex
            bg="#fff"
            borderRadius="19px"
            justifyContent="center"
            boxShadow="0px 14.3333px 27.11px -4.77778px rgba(57, 112, 253, 0.4), 0px 4.77778px 7.16667px -2.38889px rgba(0, 58, 204, 0.03)"
            width="152px"
            height="120px"
            mb="24px"
            css={css`
              @media (max-width: 780px) {
                margin-top: 60px;
              }
            `}
          >
            <Image
              src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1678776920/main-web/aws-microsite/aws_ylxk9h.png"
              alt="aws"
              minWidth="92x"
              width="92px"
            />
          </Flex>
          <Heading
            mb="4px"
            fontSize="60px"
            fontWeight="700"
            color="#1F2A37"
            lineHeight="1.2"
            letterSpacing="-0.01em"
            css={css`
              @media (max-width: 780px) {
                font-size: 40px;
              }
            `}
          >
            Hasura on AWS
          </Heading>
          <Text
            fontSize="24px"
            fontWeight="400"
            color="#111927"
            lineHeight="1.5"
            letterSpacing="-0.01em"
            width="60%"
            css={css`
              @media (max-width: 960px) {
                width: 85%;
                font-size: 20px;
              }
            `}
          >
            Ready to ship applications and APIs up to 10x faster? Accelerate your journey to building modern, cloud-native applications with Hasura on AWS.
          </Text>
          <div className="buttonWrapper">
            <a href="https://aws.amazon.com/marketplace/pp/prodview-h7qmhw3draxh4?sr=0-1&ref_=beagle&applicationId=AWSMPContessa" target="_blank" rel="noopener noreferrer">
              <StyledButton variant="blue_600" fontWeight="500">
                Try on AWS
                <Icon className="right-arrow" variant="arrow_right" size="sm" color="white" />
              </StyledButton>
            </a>
            <a href="/contact-us/" onClick={scrollToTop}>
              <StyledButton variant="neutral_400_transparent_bg" fontWeight="500">
                Contact a Hasura Specialist
                <Icon className="right-arrow" variant="arrow_right" size="sm" color="neutral_500" />
              </StyledButton>
            </a>
          </div>
        </StyledTopBannerWrapper>
      </StyledContainerWrapper>
    </StyledSectionWrapperGradient>
  );
};

export default HeroBanner;
